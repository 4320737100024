<template>
  <div class="add-hosptail">
    <div class="box">
      <div class="searchrbd ">
        <el-button type="primary" @click="addHosptailDialog = true" style="margin-left: 10px;margin-right: 10px;font-size: 16px"
        >新增医院</el-button
        >
        <upload-excel @hospital-child="uploadExcel"></upload-excel>
        <el-button @click="downFile" type="primary" style="margin-right: 16px;font-size: 16px">下载模板</el-button>
        <el-input v-model="Key" placeholder="请输入关键字" style="width: 300px;margin-right: 20px"></el-input>
        <el-button type="primary" @click="queryHospital">查询</el-button>
      </div>
    </div>
    <div style="width: 80%;margin-left: 2%" class="primary">
      <el-table
        max-height="660px"
        ref="multipleTable"
        :data="dataList"
        tooltip-effect="dark"
        style="width: 100%"
        :header-cell-style="{
            color: '#333333',
            backgroundColor: '#FBFBFD',
          }"
        row-key="id"
        border
        lazy
      >
        <el-table-column
          type="index"
          label="序号"
          width="60"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="HospitalName"
          label="医院名称"
          min-width="80"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="HospitalGrade"
          label="医院等级"
          width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="ProvinceCode"
          label="医院编码"
          width="120"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="HospitalAddress"
          label="医院地址"
          min-width="80"
          align="center"
        ></el-table-column>
        <el-table-column label="配置" width="180" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              style="margin-right: 10px;font-size: 16px; color: #3978E7;font-weight: 400;"
              @click="showEditHosptail(scope.row)"
            >编辑</el-button
            >
            <el-button
              style="font-size: 16px;color: #3978E7;font-weight: 400;"
              type="text"
              @click="showDelHosptail(scope.row)"
            >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="insert-hosptail">
      <!-- 新建文件夹 -->
      <el-dialog
        :visible.sync="addHosptailDialog"
        width="520px"
        v-dialogDrag
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">新增医院信息</span>
        </div>
        <el-form label-width="100px">
          <el-form-item label="医院名称:">
            <el-input v-model="addHosptailForm.HospitalName" placeholder="请输入医院名称"></el-input>
          </el-form-item>
          <el-form-item label="医院等级:">
            <el-input v-model="addHosptailForm.HospitalGrade" placeholder="请输入医院等级"></el-input>
          </el-form-item>
          <el-form-item label="医院编码:">
            <el-input v-model="addHosptailForm.ProvinceCode" placeholder="请输入医院等级"></el-input>
          </el-form-item>
          <el-form-item label="医院地址:">
            <el-input v-model="addHosptailForm.HospitalAddress" placeholder="请输入医院地址"></el-input>
          </el-form-item>
        </el-form>
        <div class="btn">
          <el-button @click="addHosptailDialog = false">取消</el-button>
          <el-button @click="submintHosptail">确定</el-button>
        </div>
      </el-dialog>
    </div>
    <div class="insert-hosptail">
      <!-- 新建文件夹 -->
      <el-dialog
        :visible.sync="editHosptailDialog"
        width="520px"
        v-dialogDrag
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">修改医院信息</span>
        </div>
        <el-form label-width="100px">
          <el-form-item label="医院名称:">
            <el-input v-model="editHosptailForm.HospitalName" placeholder="请输入医院名称"></el-input>
          </el-form-item>
          <el-form-item label="医院等级:">
            <el-input v-model="editHosptailForm.HospitalGrade" placeholder="请输入医院等级"></el-input>
          </el-form-item>
          <el-form-item label="医院编码:">
            <el-input v-model="editHosptailForm.ProvinceCode" placeholder="请输入医院编码"></el-input>
          </el-form-item>
          <el-form-item label="医院地址:">
            <el-input v-model="editHosptailForm.HospitalAddress" placeholder="请输入医院地址"></el-input>
          </el-form-item>
        </el-form>
        <div class="btn">
          <el-button @click="editHosptailDialog = false">取消</el-button>
          <el-button @click="submintEditHosptail">确定</el-button>
        </div>
      </el-dialog>
    </div>

    <!--    删除成员弹框-->
    <div class="systemsetting">
      <el-dialog
        top="35vh"
        :visible.sync="delHospitalDialog"
        width="510px"
        v-dialogDrag
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">提示</span>
        </div>
        <div style="text-align: center">
          <i class="el-icon-warning" style="color: #FFBA00;font-size: 24px ;margin-right: 5px"></i>
          <span style="font-size: 18px;color: #666">此操作将永久删除该医院，是否继续？</span>
        </div>
        <div class="btn">
          <button  @click="delHospitalDialog = false">取消</button>
          <button @click="submitDel">确定</button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import UploadExcel from "./upload-excel.vue"
import { insertHospitalInfo, getHospitalList, addProjectCenter, delHospitalInfo,updateHospitalInfo,
  downLoadTemplate
} from "@/api/project.js"
export default {
name: "addHosptail",
  components: {
    UploadExcel
  },
  created() {
    this.hostptailList()
  },
  data() {
    return {
      delItem: {}, // 删除的对象
      Key: "",
      dataList: [],// 医院的信息
      PurchaseId: window.sessionStorage.getItem('PurchaseId'),
      Type: window.sessionStorage.getItem('Type'),
      delHospitalDialog: false, // 删除医院弹框
      editHosptailDialog: false, // 编辑医院弹框
      // 新建项目单位信息
      CenterForm: {
        ProjectId: "",
        CenterInfo: [
          {
            HospitalId: "",
            CenterCode: "",
          },
        ],
      },
      restaurants: [], //医院搜索
      unitstate: "",
      selectArr: [], // 右边
      hospitalData: [],// 搜索出来的医院列表
      multipleSelection: [], // 左选
      nowSelectRightData: [], // 右选
      addHosptailDialog: false, //新增医院弹框
      addHosptailForm: {
        HospitalName: "",
        HospitalGrade: "",
        HospitalAddress: "",
        ProvinceCode: ""
      },
      editHosptailForm: {
      }
    }
  },
  methods: {
    uploadExcel(data) { //导入成功刷新列表
      if (data) {
        this.hostptailList()
      }
    },
    queryHospital() { // 查询医院
      this.hostptailList()
    },
    hostptailList() {
      getHospitalList({key:this.Key}).then(res => {
        if (res.data.Status == 1) {
          console.log(res,'表格数据')
          this.dataList = res.data.Entity
        }
      })
    },
    downFile() { //下载模板
      downLoadTemplate().then(res => {
        console.log(res,'sju')
        let data = res.data;
        let filename = decodeURIComponent(res.headers.filename)
        this.downLoadXls(data,filename)
      })
    },
    downLoadXls(data, filename) {
      //接收的是blob，若接收的是文件流，需要转化一下
      // var b = new Blob([data], { type: "application/vnd.ms-excel" });
      if (typeof window.chrome !== "undefined") {
        // Chrome version
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(data);
        link.download = filename;
        link.click();
      } else if (typeof window.navigator.msSaveBlob !== "undefined") {
        // IE version
        var blob = new Blob([data], { type: "application/force-download" });
        window.navigator.msSaveBlob(blob, filename);
      } else {
        // Firefox version
        var file = new File([data], filename, {
          type: "application/force-download",
        });
        window.open(URL.createObjectURL(file));
      }
    },
    submintEditHosptail() { // 提交编辑医院
      updateHospitalInfo(this.editHosptailForm).then(res => {
        if(res.data.Status == 1) {
          this.$message.success("修改成功")
          this.editHosptailDialog = false
          this.hostptailList()
        } else {
          this.$message.error("修改失败")
        }
      })
    },
    showEditHosptail(item) { // 编辑医院
      this.editHosptailDialog = true
      this.editHosptailForm= JSON.parse(JSON.stringify(item))
    },
    submitDel() { //提交删除
      delHospitalInfo({Id: this.delItem.Id}).then( res=> {
        if(res.data.Status == 1) {
          this.$message.success(res.data.Message)
          this.hostptailList()
          this.delHospitalDialog = false
        } else {
          this.$message.error(res.data.Message)
        }
      })
    },
    showDelHosptail(item) { // 删除医院
        this.delItem = item
        this.delHospitalDialog = true
    },
    async addCenter() {
      let pcentArr = [];
      this.selectArr.forEach((item) => {
        pcentArr.push({
          HospitalId: item.Id,
          CenterCode: item.CenterCode,
        });
      });
      this.CenterForm.CenterInfo = pcentArr;
      const res = await addProjectCenter(this.CenterForm);
      console.log(res)
    },
    // 添加
    handelSelect() {
      // console.log(this.multipleSelection);
      // 左侧选中并添加到右侧
      this.selectArr = this.selectArr.concat(this.multipleSelection);
      // console.log(this.selectArr);
      // 删除左侧数据
      for (let index = this.hospitalData.length - 1; index >= 0; index--) {
        const element = this.hospitalData[index];
        this.multipleSelection.forEach((item) => {
          if (element.Id === item.Id) {
            this.hospitalData.splice(index, 1);
          }
        });
      }
    },
    // 移除
    handleRemoveSelect() {
      this.hospitalData = this.hospitalData.concat(this.nowSelectRightData);
      // 删除左侧数据
      for (let index = this.selectArr.length - 1; index >= 0; index--) {
        const element = this.selectArr[index];
        this.nowSelectRightData.forEach((item) => {
          if (element.Id === item.Id) {
            this.selectArr.splice(index, 1);
          }
        });
      }
    },
    handleSelectionChange(val) { //全选
      this.multipleSelection = val;
    },
    checkRightAll(val) { // 全选
      this.nowSelectRightData = val;
    },
    unithandleSelect(item) {// 单位维护页面医院添加
      this.hospitalData = []
      var isBoole = true;
      this.hospitalData.forEach((element) => {
        if (element.Id == item.Id) {
          isBoole = false;
        }
      });
      if (isBoole) {
        // 添加选中
        this.hospitalData.push(item);
      }
      console.log(this.hospitalData,'医院的值',this.unitstate);
    },
    // 单位维护页面医院搜索
    async getHospital(keyValue) {
      let params = { key: keyValue };
      let res = await getHospitalList(params);
      if (res.data.Status == 1) {
        res.data.Entity.forEach((element) => {
          element.value = element.HospitalName;
        });
        this.restaurants = res.data.Entity;
      } else {
        this.restaurants = [];
      }
      return this.restaurants;
    },
    async querySearchAsync(queryString, cb) {
      // console.log(queryString);
      let arr = await this.getHospital(queryString);

      // 调用 callback 返回建议列表的数据
      // console.log(arr);
      cb(this.restaurants);
    },
    async submintHosptail() {
      const res = await insertHospitalInfo(this.addHosptailForm);
      // console.log(res);
      if (res.data.Status == 1) {
        this.addHosptailDialog = false;
        this.addHosptailForm = {
            HospitalName: "",
            HospitalGrade: "",
            HospitalAddress: "",
            ProvinceCode: ""
        };
        this.hostptailList()
        return this.$message.success(res.data.Message);
      } else {
        return this.$message.error(res.data.Message);
      }
    },
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/mixin.scss";
  .add-hosptail {
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #F0F7FD;
    .box {
      margin: 20px;
    }
    .searchrbd {
      display: flex;
    }
  }
  .insert-hosptail /deep/.el-dialog {
    border-radius: 10px;
    .el-dialog__header {
      border-radius: 10px 10px 0px 0px;
      background: #3388ff;
      margin-bottom: 20px;
      .header-title {
        .title-name {
          display: inline-block;
          width: 10px;
          height: 10px;
          border: 4px solid #ffffff;
          border-radius: 50%;
          vertical-align: middle;
          margin-right: 4px;
          margin-bottom: 4px;
        }
        .title-age {
          vertical-align: top;
          display: inline-block;
          @include add-size($font_size_16);
          color: #ffffff;
        }
      }
      .el-dialog__headerbtn .el-dialog__close {
        color: #fff;
      }
    }
    .el-form {
      .el-form-item {
        .el-form-item__label {
          @include add-size($font_size_16);
          color: #666666;
        }
        .el-input {
          @include add-size($font_size_16);
          width: 390px;
        }
        .el-icon-circle-plus-outline {
          width: 30px;
          display: inline-block;
          font-size: 30px;
          vertical-align: middle;
        }
        .pspan {
          display: inline-block;
          width: 360px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          vertical-align: middle;

          .spanname {
            @include add-size($font_size_16);
            margin-left: 6px;
          }
        }
        .block {
          .el-date-editor--daterange.el-input,
          .el-date-editor--daterange.el-input__inner,
          .el-date-editor--timerange.el-input,
          .el-date-editor--timerange.el-input__inner {
            width: 390px;
          }
        }
        .el-radio__label{
          @include add-size($font_size_16);
        }
      }
    }
    .btn {
      margin-top: 52px;
      text-align: center;
      button:nth-child(1) {
        width: 180px;
        background: #ffffff;
        border: 1px solid #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #3388ff;
        margin-right: 20px;
      }
      button:nth-child(2) {
        width: 180px;
        //background: #ffffff;
        border: 1px solid #3388ff;
        background: #3388ff;
        height: 50px;
        border-radius: 10px;
        font-size: 24px;
        color: #fff;
      }
    }
  }
.systemsetting /deep/.el-dialog {
     border-radius: 10px;
     .el-dialog__header {
       border-radius: 10px 10px 0px 0px;
       background: #3388ff;
       margin-bottom: 20px;
       .header-title {
         .title-name {
           display: inline-block;
           width: 10px;
           height: 10px;
           border: 4px solid #ffffff;
           border-radius: 50%;
           vertical-align: middle;
           margin-right: 4px;
           margin-bottom: 4px;
         }
         .title-age {
           vertical-align: top;
           display: inline-block;
           @include add-size($font_size_16);
           color: #ffffff;
         }
       }
       .el-dialog__headerbtn .el-dialog__close {
         color: #fff;
       }
     }
      .el-form {
        .el-form-item {
          .el-form-item__label {
            @include add-size($font_size_16);
            color: #666666;
          }
          .el-input {
            @include add-size($font_size_16);
            width: 390px;
          }
          .el-icon-circle-plus-outline {
            width: 30px;
            display: inline-block;
            font-size: 30px;
            vertical-align: middle;
          }
          .pspan {
            display: inline-block;
            width: 360px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            vertical-align: middle;

            .spanname {
              @include add-size($font_size_16);
              margin-left: 6px;
            }
          }
          .block {
            .el-date-editor--daterange.el-input,
            .el-date-editor--daterange.el-input__inner,
            .el-date-editor--timerange.el-input,
            .el-date-editor--timerange.el-input__inner {
              width: 390px;
            }
          }
          .el-radio__label{
            @include add-size($font_size_16);
          }
        }
      }
     .btn {
       margin-top: 52px;
       text-align: center;
       button:nth-child(1) {
         width: 180px;
         background: #ffffff;
         border: 1px solid #3388ff;
         height: 50px;
         border-radius: 10px;
         font-size: 24px;
         color: #3388ff;
         margin-right: 20px;
       }
       button:nth-child(2) {
         width: 180px;
         //background: #ffffff;
         border: 1px solid #3388ff;
         background: #3388ff;
         height: 50px;
         border-radius: 10px;
         font-size: 24px;
         color: #fff;
       }
     }
   }
.primary /deep/.el-table th {
  @include add-size($font_size_16);
  vertical-align: middle;
}
.primary /deep/.el-table td {
  @include add-size($font_size_16);
  vertical-align: middle;
  padding: 0;
}
.primary /deep/.el-table__row {
  padding: 0;
  @include add-size($font_size_16);
  vertical-align: middle;
}
.primary {
  ::-webkit-scrollbar {
    width: 8px!important;
    height: 8px;
  }
  ::-webkit-scrollbar-thumb {
    //width: 20px;
    //height: 4px;
    background-color: #1890ff;
    border-radius: 10px;
  }
}
</style>
