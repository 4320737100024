<template>
  <div class="app-container">
    <upload-excel-component :on-success="handleSuccess" :before-upload="beforeUpload" />
  </div>
</template>

<script>
import UploadExcelComponent from '@/components/UploadExcel/index.vue'
import {insertHospitalInfo} from "@/api/project";

export default {
  name: 'UploadExcel',
  components: { UploadExcelComponent },
  data() {
    return {
      successList:0, // 导入成功的数据
      erroeList: [],//
      tableData: [],
      tableHeader: [],
      num: 0,
      num1: 0,
    }
  },
  methods: {
    edit(index,row) {
      console.log(row,index)
    },
    add(index,row) {
      console.log(row,index)
    },
    beforeUpload(file) {
      // const isLt1M = file.size / 1024 / 1024 < 1
      // if (isLt1M) {
      //   return true
      // }
      if (file) {
        return true
      }
      // this.$message({
      //   message: 'Please do not upload files larger than 1m in size.',
      //   type: 'warning'
      // })
      return false
    },
   async downList() {
     this.successList= 0
     var arr = []
     function unique1(str){
       str = String(str)
       if (!str) {
         return false
       }
       for (var i = 0; i < arr.length; i++) {
         if(str.indexOf(arr[i]) !=-1){
           return false
         }
       }
       arr.push(str)
       return true;
     }
     for (var j = 0; j< this.tableData.length; j++) {
       var item = this.tableData[j]
       if (unique1(item["单位名称"])) {
         this.num ++
         var obj = {
           HospitalName: "",
           HospitalGrade: "",
           ProvinceCode: "",
           HospitalAddress: ""
         }
         for (var key in item) {
           if (key == "单位名称") {
             obj.HospitalName = item[key]
           }
           if (key == "等级") {
             obj.HospitalGrade = item[key]
           }
           if (key == "编码") {
             obj.ProvinceCode = item[key]
           }
           if (key == "地址") {
             obj.HospitalAddress = item[key]
           }
         }
         // insertHospitalInfo(obj).then( res=> {
         var res = await insertHospitalInfo(obj)
         console.log(res)
         if (res.data.Status == 1) {
           this.successList ++
         } else {
           console.log('错误')
         }
         this.num1++
         var day = new Date()
         console.log(this.num1,day.getTime())
           if (this.num1 >= this.num) {
             var title = "导入完毕" + "," + "导入成功" + this.successList + "个"
             this.$message.success(title)
             this.$emit('hospital-child', true)
           }
         // })
       }
     }
    },
    handleSuccess({ results, header }) {
      this.tableData = results
      console.log(header)
      this.downList()
    }
  }
}
</script>
